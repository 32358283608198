.simulation-header {
  background: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 30px 0px 0px 0px;
  width: calc(100vw - 165px);
  height: 80px;
  padding: 1rem 2rem;

  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.simul-header-ham {
  display: none;
  width: 25px;
  height: 20px;
  margin-right: px;
  position: relative;
  cursor: pointer;
}

.simul-header-ham>span {
  display: block;
  background: var(--dark);
  width: 100%;
  height: 2px;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  transition: .3s;
}

.simul-header-ham>span:nth-of-type(1) {
  top: 0%;
}

.simul-header-ham>span:nth-of-type(2) {
  top: 50%;
}

.simul-header-ham>span:nth-of-type(3) {
  top: 100%;
}

.simul-header-ham.on>span:nth-of-type(1) {
  top: 65%;
  transform: translateX(-50%) rotate(45deg);
}

.simul-header-ham.on>span:nth-of-type(2) {
  width: 0;
}

.simul-header-ham.on>span:nth-of-type(3) {
  top: 70%;
  transform: translateX(-50%) rotate(-45deg);
}

.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
}

.home-container:hover svg path {
  fill: var(--main);
  transition: 0.3s;
}

.simul-header-right {
  display: flex;
  align-items: center;
}

.noti-container {
  margin: 0px 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

.noti-container:hover svg path {
  fill: var(--green);
  transition: 0.3s;
}

.noti-dot {
  display: none;
  background: var(--green);
  border-radius: 50%;
  width: 7px;
  height: 7px;

  position: absolute;
  right: 1px;
  top: 1px;
}

.noti-dot.on {
  display: flex;
}



/* Profile - button */
.profile-container {
  cursor: pointer;
}

.profile-container .profile-dropdown {
  min-width: 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

/* Profile - Temporary design */
.profile-avatar {
  background: var(--gray);
  border-radius: 100%;
  width: 35px;
  height: 35px;
  margin-right: 10px;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  transition: .3s;
}

.profile-avatar>img {
  width: 100%;
  height: 100%;
}

.profile-container .profile-dropdown>li>button {
  color: var(--dark);
  width: auto;
  height: 30px;
  font-size: 18px;
}

.profile-container:hover .profile-dropdown>li>button {
  border-top: none;
  background: white;
  color: var(--main);
}

.profile-container .profile-dropdown-content {
  background: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  height: 0;
  overflow: hidden;

  position: absolute;
  left: 0%;
  top: 50px;
  z-index: 1;
  transition: 0.3s;
}

.profile-container:hover .profile-dropdown .profile-dropdown-content {
  height: 200px;
}

.profile-container .profile-dropdown-content>ul>li>* {
  display: block;
  height: 50px;
  text-align: center;
  font-size: 15px;
  line-height: 50px;
  transition: .3s;
}

.profile-container .profile-dropdown-content>ul>li:hover>* {
  color: var(--main);
}

@media (max-width:1280px) {
  .simulation-header {
    border-radius: 0px;
    width: 100%;
    height: 50px;
    padding: 1rem 20px;
    justify-content: space-between;
  }

  .simul-header-ham {
    display: block;
  }

  .home-container {
    font-size: 18px;
  }

  .noti-container {
    font-size: 20px;
  }

  .profile-avatar {
    width: 25px;
    height: 25px;
  }

  .profile-container .profile-dropdown>li>button {
    font-size: 15px;
  }

  .simul-header-ham {
    width: 22px;
    height: 18px;
  }
}

@media(max-width:600px) {
  .simul-header-ham {
    width: 20px;
    height: 15px;
    margin-right: 20px;
  }

  .noti-dot {
    width: 8px;
    height: 8px;
  }

  .profile-avatar {
    width: 25px;
    height: 25px;
  }

  .profile-container .profile-dropdown {
    min-width: 80px;
  }

  .profile-container .profile-dropdown>li>button {
    font-size: 15px;
  }

  .profile-container .profile-dropdown-content>ul>li>* {
    font-size: 13px;
  }
}
