.simulation-content {
  background: white;
  border-radius: 30px 0px 0px 30px;
  width: calc(100vw - 165px);
  height: 100vh;
  padding-top: 80px;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 15;
  overflow-y: auto;
}

.simulation-content::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.simulation-content::-webkit-scrollbar-thumb {
  border: 2px solid var(--gray);
  background: var(--dark);
  border-radius: 5rem;
}

.simulation-content::-webkit-scrollbar-track {
  background: var(--gray);
  border-radius: 5rem;
}

@media(max-width:1280px) {
  .simulation-content {
    border-radius: 0px;
    width: 100%;
    padding-top: 50px;
  }
}
