.simulation-sidebar {
  background: var(--dark);
  width: 200px;
  height: 100vh;
  padding-left: 10px;
  padding-right: 35px;
  padding-top: 80px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  transition: 0.3s;
}

.simul-menu-container {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.simul-menu {
  border-radius: 20px 0px 0px 20px;
  width: 100%;
  padding: 2em 1em;
  display: flex;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
}

.simul-menu svg {
  margin-right: 1em;
}

.simul-menu svg path {
  fill: white;
  transition: 0.3s;
}

.simul-menu .simul-menu-label {
  color: white;
  text-align: center;
  font-family: 'Notosans';
  font-size: 17px;
  transition: 0.3s;
}

.simul-menu.active {
  background: white;
}

.simul-menu.active svg path,
.simul-menu:hover svg path {
  fill: var(--main);
}

.simul-menu.active .simul-menu-label,
.simul-menu:hover .simul-menu-label {
  color: var(--main);
}

.sidebar-version-box{
  width:100%;
}

.sidebar-version{
  color: white;
  text-align: center;
  font-family: 'Notosans';
  font-size: 17px;
  transition: 0.3s;
  left: 40px;
  bottom: 10px;
  position: fixed;
}

.sidebar-version:hover {
  color: var(--main);
  cursor: pointer;
}

@media (max-width:1280px) {
  .simulation-sidebar {
    border-radius: 0px 20px 20px 0px;
    width: 0px;
    padding: 0;
    padding-top: 50px;
    overflow: hidden;
    align-items: flex-start;
    z-index: 99999;
  }

  .simulation-sidebar>* {
    opacity: 0;
  }

  /* Ham */
  .simul-side-ham {
    width: 25px;
    position: relative;
    cursor: pointer;
  }

  .simul-side-ham>span {
    display: block;
    background: var(--main);
    width: 100%;
    height: 2px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.3s;
  }

  .simul-side-ham.on>span:nth-of-type(1) {
    top: 65%;
    transform: translateX(-50%) rotate(45deg);
  }

  .simul-side-ham.on>span:nth-of-type(2) {
    width: 0;
  }

  .simul-side-ham.on>span:nth-of-type(3) {
    top: 70%;
    transform: translateX(-50%) rotate(-45deg);
  }

  /* sidebar on */
  .simulation-sidebar.on {
    width: 300px;
    box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.2);
  }

  .simulation-sidebar.on .simul-side-ham {
    position: absolute;
    left: 1em;
    top: 2em;
  }

  .simulation-sidebar.on>* {
    opacity: 1;
    transition-delay: 0.2s;
  }

  .simul-menu {
    border-radius: 0px;
    margin: 0.5em 0em;
    padding: 1.5em 1em;
  }

  .simul-menu:first-child {
    margin-top: 1em;
  }

  .simul-menu .simul-menu-label {
    width: calc(100% - 30px);
    text-align: left;
    font-size: 18px;
  }
}

@media(max-width:1024px) {
  .simul-menu {
    margin: 0;
  }

  .simul-menu .simul-menu-label {
    font-size: 16px;
  }
}
