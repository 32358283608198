@charset "utf-8";
@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css);

* {
  box-sizing: border-box;
  color: var(--dark);
  font-family: 'Notosans';
  line-height: 1.5;
}

a {
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

section {
  padding: 5vh 10vw;
}

button {
  outline: none;
  background: transparent;
  border: transparent;
  border-radius: 1em;
  font-size: 18px;
  cursor: pointer;
  transition: 0.3s;
}

button:disabled {
  border: transparent;
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.2);
  cursor: default;
}

button:disabled:hover {
  border: transparent;
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.2);
}

.dark button:disabled {
  border: transparent;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.3);
}

.dark button:disabled:hover {
  border: transparent;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.3);
}

:root {
  --main: rgb(1, 197, 213);

  --dark: rgb(54, 60, 77);
  --dark2: #292c34;
  --dark3: #1a1d24;

  --gray: rgb(231, 234, 242);
  --opacity-white1: rgba(255, 255, 255, 0.1);
  --opacity-white2: rgba(255, 255, 255, 0.2);
  --opacity-white3: rgba(255, 255, 255, 0.3);
  --opacity-white5: rgba(255, 255, 255, 0.5);
  --opacity-white8: rgba(255, 255, 255, 0.8);
  --opacity-dark1: rgba(0, 0, 0, 0.1);
  --opacity-dark2: rgba(0, 0, 0, 0.2);
  --opacity-dark3: rgba(0, 0, 0, 0.3);
  --opacity-dark5: rgba(0, 0, 0, 0.5);

  --yellow: #ffe600;
  --green: rgb(0, 192, 166);
  --warning: rgb(255, 87, 61);

  --gradi1: rgb(117, 222, 237);
  --gradi2: #01c5d5;
  --gradi3: #0192D5;

  --gradient: linear-gradient(45deg, var(--gradi1), var(--gradi2), var(--gradi3));
  --gradient2: linear-gradient(45deg, #12bce6, #62bf85);
}

::selection {
  background-color: var(--dark2);
  color: white;
}

body:fullscreen main.pharma-content {
  overflow: auto;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}



.box {
  padding: 5vh 15vw;
}

.box-simulation {
  height: 100%;
  padding: 20px 2em;
  overflow-y: auto;
}

.box-simulation::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.box-simulation::-webkit-scrollbar-thumb {
  border: 2px solid var(--gray);
  background: var(--dark);
  border-radius: 5em;
}

.box-simulation::-webkit-scrollbar-track {
  background: var(--gray);
  border-radius: 5em;
}

.params-container {
  padding-top: 20px;
  padding-bottom: 80px;
}

.run-container {
  padding-top: 0px;
  margin-bottom: 100px;
}

.box-text-align {
  text-align: center;
}

.box-text-align h3 {
  border: 1px solid var(--main);
  border-radius: 10px;
  padding: 1em;
  color: var(--main);
  font-size: 20px;
  font-weight: bold;
}

.page-desc {
  width: 100%;
  border-left: 3px solid var(--main);
  margin: 10px 0;
  padding: 5px 0 5px 10px;
}

/* Total number */
.total-num {
  border-left: 3px solid var(--dark);
  margin: 1em 0em;
  padding: 0em 1em;
  text-align: left;
}

.total-num>span {
  color: var(--main);
  font-family: 'Exo';
  font-weight: bold;
}

/* Sort button */
.sort-button-container {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.sort-button-container>button {
  border: 1px solid var(--gray);
  border-radius: 5px;
  margin: 0px 5px;
  font-size: 15px;
}

.sort-button-container>button:hover {
  background: var(--gray);
}

.sort-button-container>button.current-sort {
  border-color: var(--dark);
  background: var(--dark);
  color: white;
}

.sort-button-container>button.current-sort:hover {
  background: var(--dark);
}

.sort-button-container>button.current-sort svg path {
  fill: white;
}

/* New Create */
.new-create-name {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.new-enter-name {
  width: 100%;
}

.new-create .input {
  width: 100%;
}

.new-enter-name {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.popup-new-project-name,
.popup-new-process-name {
  color: var(--main);
  font-size: 18px;
}

/* Empty list - Quotation & Request */
.no-quotation {
  background: var(--dark);
  border-radius: 20px;
  margin-top: 20px;
  padding: 1em;
}

.no-quotation-desc {
  background: white;
  border-radius: 10px;
  padding: 2em;
  text-align: center;
}

.no-quotation-desc img {
  width: 200px;
  opacity: 0.5;
}

.no-quotation-desc p {
  margin-top: 20px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.3);
}

/* Bottom button */
.bottom-btn {
  background: white;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px -3px 3px rgba(0, 0, 0, 0.05);
  width: calc(100vw - 165px);
  height: 80px;
  padding: 0em 1em;
  position: fixed;
  left: 165px;
  bottom: 0px;
  z-index: 99999;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.module-select>div.bottom-btn {
  margin-bottom: 0px;
}

.bottom-btn.bottom-btn-right {
  justify-content: right;
}

.bottom-btn.bottom-btn-top {
  margin-top: 0px;
  margin-bottom: 20px;
}

.bottom-btn button,
.bottom-btn button.safebutton,
.bottom-btn a {
  background: var(--main);
  border-radius: 5em;
  width: 150px;
  height: 50px;
  margin-left: 20px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 00.3s;
}

.bottom-btn button:first-of-type,
.bottom-btn a:first-of-type {
  margin-left: 0px;
}

.bottom-btn button:hover,
.bottom-btn a:hover {
  color: white;
}

.bottom-btn.bottom-btn-right button:last-of-type,
.bottom-btn.bottom-btn-right a:last-of-type {
  margin-left: 20px;
}

.bottom-btn .btn-dark {
  border: 1px solid var(--dark);
  background: white;
  color: var(--dark);
}

.bottom-btn .btn-dark:hover {
  background: var(--dark);
  color: white;
}

.bottom-btn.dark .btn-dark {
  border: 1px solid white;
  background: transparent;
  color: white;
}

.bottom-btn.dark .btn-dark:hover {
  background: white;
  color: var(--dark);
}

.bottom-btn .bottom-btn-help,
.bottom-btn .bottom-btn-cro {
  border: transparent;
  background: transparent;
  width: 50px;
}

.bottom-btn .bottom-btn-help:hover,
.bottom-btn .bottom-btn-cro:hover {
  background: transparent;
  color: var(--main);
}

.hidden,
.bottom-btn button.hidden,
.bottom-btn a.hidden {
  display: none;
}

/* Popup - Help */
.help-step {
  background: var(--gray);
  border-radius: 10px;
  margin: auto;
  padding: 1em;
  text-align: left;
}

.help-step>p {
  margin: 20px 0;
  line-height: 2;
}

.help-step-num {
  font-weight: 700;
}

.help-step-btn {
  background: var(--main);
  border-radius: 5em;
  margin: 0 5px;
  padding: 5px 10px;
  color: var(--dark);
}

/* Shortcut */
section.shortcut-box {
  padding: 2em 0em;
  background: var(--gradient);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.shortcut-title {
  width: fit-content;
  text-align: center;
  font-size: 30px;
  transition: .3s;
  position: relative;
}

.shortcut-title::after {
  content: "®";
  font-size: 20px;
  position: absolute;
  right: -1em;
  top: -0.5em;
}

.shortcut-title>span {
  font-weight: bold;
}

.shortcut-box>a {
  background: white;
  border-radius: 10px;
  padding: 1em;
  transition: 0.3s;
}

.shortcut-box>a:hover {
  background: var(--dark);
  color: white;
}

/* Shortcut Button in List */
.shortcut-btn {
  width: fit-content;
  padding-left: 1em;
  display: flex;
  align-items: center;
}

.shortcut-btn-icon {
  background: var(--dark);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}

.shortcut-btn svg path {
  fill: white;
  transition: 0.3s;
}

.shortcut-btn p {
  font-size: 14px;
}

.shortcut-btn:hover .shortcut-btn-icon {
  background: var(--green);
}

.shortcut-btn:hover p {
  color: var(--green);
}

/* Ham */
.pharmaco-nav-ham {
  display: none;
  width: 25px;
  height: 20px;
  position: relative;
  cursor: pointer;
}

.pharmaco-nav-ham>span {
  display: block;
  background: white;
  width: 100%;
  height: 2px;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  transition: 0.3s;
}

.pharmaco-nav-ham>span:nth-of-type(1) {
  top: 0%;
}

.pharmaco-nav-ham>span:nth-of-type(2) {
  top: 50%;
}

.pharmaco-nav-ham>span:nth-of-type(3) {
  top: 100%;
}

.pharmaco-nav-ham.on>span:nth-of-type(1) {
  top: 65%;
  transform: translateX(-50%) rotate(45deg);
}

.pharmaco-nav-ham.on>span:nth-of-type(2) {
  width: 0;
}

.pharmaco-nav-ham.on>span:nth-of-type(3) {
  top: 70%;
  transform: translateX(-50%) rotate(-45deg);
}

/* Token owned */
.token-owned {
  display: flex;
  justify-content: center;
  align-items: center;
}

.token-owned>p {
  padding: 0em 1em;
}

.token-owned>p.token-label {
  border-right: 1px solid var(--dark);
}

.token-owned>button {
  background: var(--dark);
  border-radius: 5em;
  width: 100px;
  height: 40px;
  color: white;
  font-size: 15px;
}

.token-owned>button:hover {
  color: var(--main);
}

/* Mobile */
@media(max-width:1280px) {
  section,
  .box {
    padding: 5vh 5vw;
  }

  .bottom-btn {
    width: 100%;
    left: 0px;
  }
}

@media(max-width:950px) {
  .shortcut-title {
    font-size: 20px;
    line-height: 1.5;
  }
}

@media(max-width:910px) {
  div.new-enter-name>.input {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  div.new-enter-name input {
    width: 100%;
  }
}

@media(max-width:600px) {
  .bottom-btn button,
  .bottom-btn a {
    width: calc(100% / 2 - 10px);
    font-size: 15px;
  }

  .guide {
    width: 250px;
  }

  .guide.simul,
  .shortcut-box>button,
  .help-step>p {
    font-size: 15px;
  }
}





/* Preparing for service */
p.prepare-text {
  border: 1px solid var(--green);
  border-radius: 20px;
  width: 100%;
  margin: 10px 0px;
  padding: 1em;
  color: var(--green);
  text-align: center;
  font-family: 'Exo';
  font-weight: bold;
  font-size: 25px;
}

/* No register messagebox */
.no-reg-msg {
  color: var(--dark);
  font-family: 'Notosans';
  line-height: 1.5;
  position: relative;
}

.no-reg-msg>button {
  border: 1px solid var(--gray);
  border-radius: 5px;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 1em;
  top: 1em;
  font-size: 20px;
}

.no-reg-msg>button:hover {
  background: var(--dark);
}

.no-reg-msg>button:hover svg path {
  fill: white;
}

.no-reg-msg>p>a {
  color: var(--main);
}

.no-reg-msg>p>a:hover {
  border-bottom: 1px solid var(--main);
}

.hand-cursor {
  cursor: pointer;
}
