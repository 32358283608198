/* .pharma-content h1, h2, h3, h4, h5, h6{
  font-weight   : 900;
  margin-bottom : 20px;
} */

.pharma-content h1{
  font-size     : 2.5rem;
}

.pharma-content h2{
  font-size     : 2rem;
}

.pharma-content h3{
  font-size     : 1.5rem;
}