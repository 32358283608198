@font-face {
    font-family: 'Exo';
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
    src: url('../fonts/Exo-Regular.woff') format('woff'),
    url('../fonts/Exo-Regular.ttf') format('ttf'),
    url('../fonts/Exo-Regular.eot') format('eot');
  }
  
  @font-face {
    font-family: 'Exo';
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
    src: url('../fonts/Exo-Bold.woff') format('woff'),
    url('../fonts/Exo-Bold.ttf') format('ttf'),
    url('../fonts/Exo-Bold.eot') format('eot');
  }
  
  @font-face {
    font-family: 'Leferi';
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
    src: url('../fonts/LeferiPointWhiteOblique.woff') format('woff'),
    url('../fonts/LeferiPointWhiteOblique.ttf') format('ttf'),
    url('../fonts/LeferiPointWhiteOblique.eot') format('eot');
  }
  
  @font-face {
    font-family: 'Notosans';
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
    src: url('../fonts/NotoSans-Regular.woff') format('woff'),
    url('../fonts/NotoSans-Regular.ttf') format('ttf'),
    url('../fonts/NotoSans-Regular.eot') format('eot');
  }

  @font-face {
    font-family: 'Notosans';
    font-weight: 600;
    font-style: normal;
    font-display: fallback;
    src: url('../fonts//NotoSans-Medium.woff') format('woff'),
    url('../fonts/NotoSans-Medium.ttf') format('ttf'),
    url('../fonts/NotoSans-Medium.eot') format('eot');
  }

  @font-face {
    font-family: 'Notosans';
    font-weight: 800;
    font-style: normal;
    font-display: fallback;
    src: url('../fonts/NotoSans-Bold.woff') format('woff'),
    url('../fonts/NotoSans-Bold.ttf') format('ttf'),
    url('../fonts/NotoSans-Bold.eot') format('eot');
  }
  
