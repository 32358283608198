.title-text-first,
.title-text-last {
  font-weight: bold;
  font-size: 40px;
}

@media(max-width:800px) {
  .title-text-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }

  .title-text-first,
  .title-text-last {
    text-align: center;
  }

  .title-text-last {
    margin-left: 10px;
  }
}

@media(max-width:600px) {
  .title-text-first,
  .title-text-last {
    font-size: 30px;
  }
}
